document.querySelectorAll(".nav-item").forEach((nav)=>{
    navAction(nav);
})

function navAction(nav){
    console.log("VAN",nav)
    nav.addEventListener("click",(event)=>{
        event.currentTarget.closest("ul").querySelectorAll("li").forEach((li)=>{
            li.classList.remove("active")
        })
        let id  =  event.currentTarget.getAttribute("id")
        let topPos = document.querySelector(".bodyContent").querySelector("#"+id).getBoundingClientRect().top;;
        console.log(event.currentTarget.getAttribute("id"),(document.querySelector(".bodyContent").scrollTop + topPos ) - document.querySelector(".bodyContent").getBoundingClientRect().top)
        event.currentTarget.classList.add("active");
        //document.querySelector(".bodyContent").scrollTop = ((document.querySelector(".bodyContent").scrollTop + topPos ) - document.querySelector(".bodyContent").getBoundingClientRect().top) - 100;
        scrollTopFn( ((document.querySelector(".bodyContent").scrollTop + topPos ) - document.querySelector(".bodyContent").getBoundingClientRect().top) - 100)
    })
    //document.querySelector(".bodyContent").scrollTop = 1485.8359375
}

function scrollTopFn(topPos){
    document.querySelector(".bodyContent").scrollTo({top: topPos, behavior: 'smooth'});
}